<template>
  <div>
    <div class="d-flex justify-content-around p-1" style="">
      <div class="align-items-center d-flex flex-column items-center" style="width: 200px">
        <h4 class="text-lg mb-1 font-normal">Đội</h4>
        <v-select
          v-model="team"
          label="name"
          :clearable="false"
          :options="teamOptions"
          placeholder="Chọn đội"
          class="w-100"
        />
      </div>
      <div class="align-items-center d-flex flex-column items-center" style="width: 200px">
        <h4 class="text-lg mb-1 font-normal">Chiến dịch</h4>
        <v-select
          v-model="campaign"
          label="name"
          :clearable="false"
          :options="campaignOfTeamOptions"
          placeholder="Chọn chiến dịch"
          class="w-100"
        />
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Tổng cộng</h4>
        <h4 class="h2">{{ total }}</h4>
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Đã dùng</h4>
        <h4 class="h2">{{ use }}</h4>
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Còn lại</h4>
        <h4 class="h2">{{ left }}</h4>
      </div>
    </div>

    <div v-if="loadingUsersOfTeams" class="text-center">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        class="mr-1"
        label="Large Spinner"
      />
    </div>
    <template v-else>
      <div v-if="campaign">
        <div class="h4 mb-1 mt-2 text-center">
          Danh sách thành viên thuộc đội
          {{ team.name }}
        </div>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="usersOfCampaigns"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(assignCount)="data">
            <form-spinbutton-with-input
              v-model="data.item.assignCount"
              inline
              size="sm"
              :min="0"
              :max="data.item.assignCount + left"
              @change="(val) => handleChangeSpin(val, data.item)"
            />
          </template>
        </b-table>

        <div class="text-center">
          <base-button
            variant="primary"
            :disabled="loadingSubmit || use <= 0"
            @click="handleUpdate"
          >
            <b-spinner v-if="loadingSubmit" small />
            <span class="align-middle"> {{ $t('Update') }}</span>
          </base-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { BFormCheckbox, BSpinner, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import {
  assignToUsers,
  campaignsOfTeamList,
  teamList,
  usersOfTeamList,
} from '@/apis/apiAssignment';
import BaseButton from '@/components/base/BaseButton.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import FormSpinbuttonWithInput from '@/components/base/FormSpinbuttonWithInput.vue';

export default {
  components: {
    FormSpinbuttonWithInput,
    vSelect,
    BFormCheckbox,
    BTable,
    BSpinner,

    BaseButton,
  },

  data: () => ({
    tableColumns: [
      {
        label: 'TÊN',
        key: 'name',
      },
      {
        label: 'MÃ NHÂN VIÊN',
        key: 'members',
      },
      {
        label: 'CHƯA GỌI',
        key: 'not_call_contracts',
      },
      {
        label: 'ĐANG THEO DÕI',
        key: 'following_contracts',
      },
      {
        label: 'KHÔNG XỬ LÝ ĐƯỢC',
        key: 'give_up_contracts',
      },
      {
        label: 'THU TIỀN',
        key: 'collected_contracts',
      },
      {
        label: 'THÊM',
        key: 'assignCount',
        thStyle: { width: '250px' },
      },
    ],
    teamOptions: [],
    team: null,

    campaignOfTeamOptions: [],
    campaign: null,
    total: 0,
    use: 0,

    usersOfCampaigns: [],
    loadingUsersOfTeams: false,
    loadingSubmit: false,
  }),

  computed: {
    left() {
      return this.total - this.use;
    },
  },

  watch: {
    team(team) {
      this.fetchCampaignsOfTeams(team.id);

      this.usersOfCampaigns = [];

      this.total = team.available_contracts;
      this.use = 0;
    },
    campaign(val) {
      if (val !== null) {
        this.fetchUsersOfTeams(this.team.id);
      }
    },
  },

  mounted() {
    // call api teamOptions
    this.fetchOptions();
  },

  methods: {
    fetchOptions() {
      teamList()
        .then((res) => {
          this.teamOptions = res.data;
        })
        .catch((err) => {});
    },
    fetchCampaignsOfTeams(campaignId) {
      this.campaign = null;

      campaignsOfTeamList(campaignId)
        .then((res) => {
          this.campaignOfTeamOptions = res.data;
        })
        .catch((err) => {});
    },
    fetchUsersOfTeams(campaignId) {
      this.loadingUsersOfTeams = true;
      usersOfTeamList(campaignId)
        .then((res) => {
          this.loadingUsersOfTeams = false;

          this.usersOfCampaigns = res.data.map((item) => ({
            ...item,
            assignCount: 0,
          }));
        })
        .catch((err) => {
          this.loadingUsersOfTeams = false;
        });
    },
    handleChangeSpin(val, row) {
      this.use = 0;
      this.usersOfCampaigns.forEach((item) => {
        this.use += item.assignCount;
      });

      // Change key to force rerender row to rerender input
      // row.id = Math.floor(Math.random() * 8999 + 1000);
    },
    handleUpdate() {
      this.loadingSubmit = true;

      // pre process data
      const payload = this.usersOfCampaigns
        .filter((item) => item.assignCount > 0)
        .map((item) => ({
          user_id: item.id,
          assign_count: item.assignCount,
        }));

      assignToUsers(this.team.id, payload)
        .then((res) => {
          this.loadingUsersOfTeams = false;

          this.loadingSubmit = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Cập nhật thành công!',
            },
          });

          window.location.reload();
        })
        .catch((err) => {
          this.loadingUsersOfTeams = false;

          this.loadingSubmit = false;

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Cập nhật thất bại!',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
