<template>
  <b-card>
    <b-tabs>
      <b-tab
        v-if="
          $can(
            permissions.ASSIGNMENT.GET_v1_assignment_campaigns.action,
            permissions.ASSIGNMENT.GET_v1_assignment_campaigns.resource,
          )
        "
        title="Chiến dịch → Đội"
        active
      >
        <assignment-cam-to-team />
      </b-tab>
      <b-tab
        v-if="
          $can(
            permissions.ASSIGNMENT.GET_v1_assignment_teams.action,
            permissions.ASSIGNMENT.GET_v1_assignment_teams.resource,
          )
        "
        title="Đội → Nhân viên"
      >
        <assignment-team-to-user />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import AssignmentCamToTeam from '@/views/assignment/AssignmentCamToTeam.vue';
import AssignmentTeamToUser from '@/views/assignment/AssignmentTeamToUser.vue';
import permissions from '@/constants/permissions';

export default {
  components: {
    AssignmentTeamToUser,
    AssignmentCamToTeam,
    BTabs,
    BTab,
    BCard,
  },

  data: () => ({
    permissions,
  }),

  methods: {},
};
</script>

<style lang="scss" scoped></style>
