var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-tabs',[(
        _vm.$can(
          _vm.permissions.ASSIGNMENT.GET_v1_assignment_campaigns.action,
          _vm.permissions.ASSIGNMENT.GET_v1_assignment_campaigns.resource
        )
      )?_c('b-tab',{attrs:{"title":"Chiến dịch → Đội","active":""}},[_c('assignment-cam-to-team')],1):_vm._e(),(
        _vm.$can(
          _vm.permissions.ASSIGNMENT.GET_v1_assignment_teams.action,
          _vm.permissions.ASSIGNMENT.GET_v1_assignment_teams.resource
        )
      )?_c('b-tab',{attrs:{"title":"Đội → Nhân viên"}},[_c('assignment-team-to-user')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }