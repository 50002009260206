import jwt from '@/auth/jwt/useJwt';

// Campaign to Teams
export function campaignList(condition) {
  return jwt.axiosIns.get('/assignment/campaigns', {
    params: {
      ...condition,
    },
  });
}

export function campaignListTeam(campaignId, condition) {
  return jwt.axiosIns.get(`/assignment/campaigns/${campaignId}/teams`, {
    params: {
      ...condition,
    },
  });
}

export function assignToTeams(campaignId, payload) {
  return jwt.axiosIns.post(`/assignment/campaigns/${campaignId}/teams`, payload);
}

// Team to User
export function teamList(condition) {
  return jwt.axiosIns.get('/assignment/teams', {
    params: {
      ...condition,
    },
  });
}

export function campaignsOfTeamList(teamId, condition) {
  return jwt.axiosIns.get(`/assignment/teams/${teamId}/campaigns`, {
    params: {
      ...condition,
    },
  });
}

export function usersOfTeamList(teamId, condition) {
  return jwt.axiosIns.get(`/assignment/teams/${teamId}/users`, {
    params: {
      ...condition,
    },
  });
}

export function assignToUsers(teamId, payload) {
  return jwt.axiosIns.post(`/assignment/teams/${teamId}/users`, {
    list: payload,
  });
}
