<template>
  <b-input-group class="input-group-merge">
    <b-input-group-prepend>
      <b-button variant="outline-primary" class="py-0" size="sm" @click="valueChange(value - 1)">
        <b-icon icon="dash" font-scale="1.6" />
      </b-button>
    </b-input-group-prepend>

    <b-form-input
      :size="size"
      :value="value"
      type="number"
      class="text-center"
      number
      @change="valueChange"
    />
    <b-input-group-append>
      <b-button variant="outline-primary" class="py-0" size="sm" @click="valueChange(value + 1)">
        <b-icon icon="plus" font-scale="1.6" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BIcon,
  BIconDash,
  BIconPlus,
  BButton,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue';

export default {
  name: 'FormSpinbuttonWithInput',

  components: {
    BIcon,
    BIconDash,
    BIconPlus,
    BButton,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
  },

  props: {
    size: {
      type: String,
      required: false,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
    },
    value: {
      type: [Number, String],
      required: true,
    },
    min: {
      type: [Number, String],
      default: 1,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
  },

  methods: {
    valueChange(newValue) {
      if (newValue <= this.min) {
        newValue = this.min;
      } else if (newValue >= this.max) {
        newValue = this.max;
      }

      this.$emit('input', newValue);
      this.$emit('change', newValue);
    },
  },
};
</script>

<style scoped>
/* Remove up and down arrows inside number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
