<template>
  <div>
    <div class="d-flex justify-content-around p-1" style="">
      <div class="align-items-center d-flex flex-column items-center" style="width: 200px">
        <h4 class="text-lg mb-1 font-normal">Chiến dịch</h4>
        <v-select
          v-model="campaign"
          label="name"
          :clearable="false"
          :options="campaignOptions"
          placeholder="Chọn chiến dịch"
          class="w-100"
        />
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Tổng cộng</h4>
        <h4 class="h2">{{ total }}</h4>
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Đã dùng</h4>
        <h4 class="h2">{{ use }}</h4>
      </div>
      <div class="align-items-center d-flex flex-column items-center">
        <h4 class="text-lg mb-1 font-normal">Còn lại</h4>
        <h4 class="h2">{{ left }}</h4>
      </div>
    </div>

    <div v-if="loadingTeamsOfCampaigns" class="text-center">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        class="mr-1"
        label="Large Spinner"
      />
    </div>
    <template v-else>
      <div v-if="campaign">
        <div class="h4 mt-2 text-center">
          Danh sách đội thuộc chiến dịch
          {{ campaign.name }}
        </div>
        <b-row class="mb-1 text-center justify-content-center" cols="6">
          <b-col>
            <b-form-datepicker
              id="salary-date"
              v-model="dateFinish"
              :min="new Date()"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              locale="en-GB"
              style="z-index: 1000"
              placeholder="Chọn ngày kết thúc"
            />
          </b-col>
        </b-row>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="teamsOfCampaigns"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(add)="data">
            <form-spinbutton-with-input
              v-model="data.item.assignCount"
              inline
              size="sm"
              :min="0"
              :max="data.item.assignCount + left"
              @change="(val) => handleChangeSpin(val, data.item)"
            />
          </template>
        </b-table>

        <div class="text-center">
          <base-button
            variant="primary"
            :disabled="loadingSubmit || use <= 0"
            @click="handleUpdate"
          >
            <b-spinner v-if="loadingSubmit" small />
            <span class="align-middle"> {{ $t('Update') }}</span>
          </base-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { BFormCheckbox, BSpinner, BTable, BFormDatepicker, BRow, BCol } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { assignToTeams, campaignList, campaignListTeam } from '@/apis/apiAssignment';
import BaseButton from '@/components/base/BaseButton.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import FormSpinbuttonWithInput from '@/components/base/FormSpinbuttonWithInput.vue';

export default {
  components: {
    FormSpinbuttonWithInput,
    vSelect,
    BFormCheckbox,
    BTable,
    BSpinner,
    BFormDatepicker,
    BRow,
    BCol,

    BaseButton,
  },

  data: () => ({
    tableColumns: [
      {
        label: 'TÊN',
        key: 'name',
      },
      {
        label: 'THÀNH VIÊN',
        key: 'members',
      },
      {
        label: 'CHƯA GỌI',
        key: 'not_call_contracts',
      },
      {
        label: 'ĐANG THEO DÕI',
        key: 'following_contracts',
      },
      {
        label: 'KHÔNG XỬ LÝ ĐƯỢC',
        key: 'give_up_contracts',
      },
      {
        label: 'THU TIỀN',
        key: 'collected_contracts',
      },
      {
        label: 'THÊM',
        key: 'add',
        thStyle: { width: '250px' },
      },
    ],
    campaignOptions: [],
    campaign: null,
    total: 0,
    use: 0,

    teamsOfCampaigns: [],
    loadingTeamsOfCampaigns: false,
    loadingSubmit: false,
    dateFinish: null,
  }),

  computed: {
    left() {
      return this.total - this.use;
    },
  },

  watch: {
    campaign(campaign) {
      this.fetchTeamsOfCampaigns(campaign.id);

      this.total = campaign.available_contracts;
      this.use = 0;
    },
  },

  mounted() {
    // call api campaignOptions
    this.fetchCampaigns();
  },

  methods: {
    fetchCampaigns() {
      campaignList()
        .then((res) => {
          this.campaignOptions = res.data;
        })
        .catch((err) => {});
    },
    fetchTeamsOfCampaigns(campaignId) {
      this.loadingTeamsOfCampaigns = true;
      campaignListTeam(campaignId)
        .then((res) => {
          this.loadingTeamsOfCampaigns = false;

          this.teamsOfCampaigns = res.data.map((item) => ({
            ...item,
            assignCount: 0,
          }));
        })
        .catch((err) => {
          this.loadingTeamsOfCampaigns = false;
        });
    },
    handleChangeSpin(val, row) {
      this.use = 0;
      this.teamsOfCampaigns.forEach((item) => {
        this.use += item.assignCount;
      });

      // Change key to force rerender row to rerender input
      // row.id = Math.floor(Math.random() * 8999 + 1000);
    },
    handleUpdate() {
      this.loadingSubmit = true;

      // pre process data
      const payload = this.teamsOfCampaigns
        .filter((item) => item.assignCount > 0)
        .map((item) => ({
          team_id: item.id,
          assign_count: item.assignCount,
        }));

      assignToTeams(this.campaign.id, {
        finished_at: this.dateFinish,
        list: payload,
      })
        .then((res) => {
          this.loadingSubmit = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Cập nhật thành công!',
            },
          });

          window.location.reload();
        })
        .catch((err) => {
          this.loadingSubmit = false;

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Cập nhật thất bại!',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
